import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import logo from '../images/CC-001.png';

import cloud2 from '../images/cloud2.png';
import planet from '../images/planet.png';
import spacekitty from '../images/spacekitty.png';

import React, { Component } from "react";

import gsap from 'gsap'
import { navigate } from 'gatsby';

export function _addProduct(id, pushid, name, size, variant, quantity) {

    const product =
    {
        id: id,
        pushid: pushid,
        date: new Date(),
        name: name,
        size: size,
        variant: variant,
        quantity: quantity,
    }

    return product;

}

export function _productChange(event, product) {
    let btn
    if (!event.target)
        btn = event
    else {
        btn = event.target.value
    }


    if (btn === 'confirm') {
        if (product.quantity <= 0)
            return product;

        return _addProduct(product.id, product.pushid, product.name, product.size, product.variant, product.quantity);

    }
    else {
        if (btn === 'up') {
            if (product.quantity < 5)
                product.quantity = parseInt(product.quantity) + 1;
        }
        else if (btn === 'down') {
            if (product.quantity > 0)
                product.quantity = parseInt(product.quantity) - 1;
        }
        else if (Number.isInteger(btn)) { //variant
            if (product.quantity === 0)
                product.quantity = parseInt(product.quantity) + 1;
        }
        else if (btn) //size
        {
            product.size = btn
        }


        return product;
    }

}

export default class Cart extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitCart = this.submitCart.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.form = React.createRef();
        this.orders = React.createRef();
        this.display = React.createRef();

        this.savedCart = []


        this.state = {
            orders: [],
            productsDisplay: '',
        }
    }

    submitCart = (event) => {
        this.handleSubmit();
        this.form.current.submit()
    }

    handleClear(event) {
        sessionStorage.clear()
        this.setState({ orders: [] })

    }


    handleSubmit() {

        var pushOrder = ''
        var pushDisplay = ''

        if (this.savedCart.length === 0) {
            alert("cart is empty")
            return;
        }

        for (let i = 0; i < this.savedCart.length; i++) {
            const push = this.savedCart[i].pushid + " " + this.savedCart[i].size + " - " + this.savedCart[i].variant + " " + this.savedCart[i].quantity;
            const display = this.savedCart[i].size + " - " + this.savedCart[i].variant + ' - ' + this.savedCart[i].name;
            pushOrder = push + ", " + pushOrder;
            pushDisplay = display + ", " + pushDisplay;
        }

        this.orders.current.value = pushOrder
        this.display.current.value = pushDisplay

    }

    componentDidMount() {
        this.savedCart = JSON.parse(window.sessionStorage.getItem('orders'))
        if (this.savedCart != undefined) {
            this.submitCart();

        }
        else {

            navigate('/')
            alert("cart is empty")
        }
         
    }

    componentWillUnmount() {
    }

    render() {


        return (<><form action="https://server.cozyclub.us/create-checkout-session" ref={this.form} onSubmit={this.submitCart} method="POST">
            <input type="hidden" name="orders" id="orders" ref={this.orders} />
            <input type="hidden" name="display" id="display" ref={this.display} />

        </form>
        </>
        );
    }
}